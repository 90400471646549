<!--  -->
<template>
  <div class=''>
    <div style="font-size: 35px">预约、问诊历史</div>
    <el-divider></el-divider>
<!--    预约历史部分，数据格式和医生端看到的待完成预约一致-->
    <div style="font-size: 25px;text-align: center">预约历史</div>
<!--    <el-table :data="appointmentHistory"
              style="width: 100%">
      <el-table-column label="预约医生" prop="appointDoc"></el-table-column>
      <el-table-column label="预约日期" prop="appointDate"></el-table-column>
      <el-table-column label="预约时间" prop="appointTime"></el-table-column>

      <el-table-column label="症状备注" prop="appointRemark">
        <template v-slot="scope1">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope1.row.appointRemark}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
&lt;!&ndash;      暂定用户使用邀请链接进房。考虑将这一按钮的激活条件和邀请链接绑定，当邀请链接被医生提交后，这里读取到邀请链接，激活按钮，用户使用按钮进房。
      不知道这一激活过程能不能动态的完成。&ndash;&gt;
      <el-table-column label="就诊状态" prop="appointState">
        <template v-slot="scope2">
&lt;!&ndash;          if({{ scope2.row.appointState }}===1){
          <el-button type="primary" >立即就诊</el-button>
          }
          else is({{ scope2.row.appointState }}===0){
          <el-button type="primary" disabled>立即就诊</el-button>
          }&ndash;&gt;
          <span v-if="scope2.row.appointState">
            <el-button type="primary"
                       @click="open2">立即就诊</el-button>
          </span>
          <span v-else>
            <el-button type="primary" disabled>稍后就诊</el-button>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="取消预约" prop="appointCancel">
        <template v-slot="scope3">
          &lt;!&ndash;          if({{ scope2.row.appointState }}===1){
                    <el-button type="primary" >立即就诊</el-button>
                    }
                    else is({{ scope2.row.appointState }}===0){
                    <el-button type="primary" disabled>立即就诊</el-button>
                    }&ndash;&gt;
          <span v-if="scope3.row.appointCancel">
            <el-button type="danger" >确定取消</el-button>
          </span>
          <span v-else>
            <el-button type="danger" disabled>不可取消</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>-->

    <el-table :data="appointHisString">
      <el-table-column label="预约医生" prop="docId"></el-table-column> <!--!!!漏传了一个医生姓名，这里应该是医生姓名！！！-->
      <el-table-column label="预约时间" prop="dateTime"></el-table-column>

      <el-table-column label="症状备注" prop="symptoms">
        <template v-slot="scope11">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope11.row.symptoms}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="就诊状态" prop="appointState">
        <template v-slot="scope12">
          <span v-if="scope12.row.inviteLink===null">
            <el-button type="primary" disabled>稍后就诊</el-button>
          </span>
          <span v-else>
            <el-button type="primary" @click="open1(scope12.row)">立即就诊</el-button>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="取消预约" prop="status">
        <template v-slot="scope13">
          <span v-if="new Date()<scope13.row.fixTime">
            <el-button type="danger" @click="appointCancel(scope13.row)">确定取消</el-button>
          </span>
          <span v-else>
            <el-button type="danger" disabled>不可取消</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-divider></el-divider>
    <div style="font-size: 25px;text-align: center">就诊历史</div>
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>
<!--    !!!目前绑定的是id，这个搜索框不能用-->
<!--    <el-table :data="medicalHistory.filter(data => !search || data.medicalDoc.toLowerCase().includes(search.toLowerCase()))"
              style="width: 100%">
      <el-table-column label="接诊医生" prop="medicalDoc"></el-table-column>
      <el-table-column label="就诊时间" prop="medicalTime"></el-table-column>

      <el-table-column label="症状备注" prop="medicalRemark">
        <template v-slot="scope4">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope4.row.medicalRemark}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊断结论" prop="medicalResult">
        <template v-slot="scope4">
          <el-popover
              title="诊断结论"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope4.row.medicalResult}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="医生备注" prop="medicalAdvice">
        <template v-slot="scope4">
          <el-popover
              title="医生备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope4.row.medicalAdvice}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

    </el-table>-->
    <el-table :data="treatHisString.filter(data => !search || data.docId.toLowerCase().includes(search.toLowerCase()))"
              :default-sort = "{prop: 'diagnosisTime', order: 'descending'}">
      <el-table-column label="接诊医生" prop="docId"></el-table-column>
      <el-table-column label="就诊时间" prop="diagnosisTime" sortable></el-table-column>

      <el-table-column label="症状备注" prop="symptoms">
        <template v-slot="scope14">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope14.row.symptoms}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊断结论" prop="diagnosisConclusion">
        <template v-slot="scope15">
          <el-popover
              title="诊断结论"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope15.row.diagnosisConclusion}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="医嘱备注" prop="remarks">
        <template v-slot="scope16">
          <el-popover
              title="医嘱备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope16.row.remarks}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

    </el-table>


<!--    <el-divider></el-divider>
    <div>{{treatHisString}}</div>
    <div>{{typeof treatHisString}}</div>
    <el-divider></el-divider>-->



  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import Cookies from "js-cookie";
import Vue from "vue";

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      userId: 1,
      userType: '小屋',
      uname: '心狗小屋',

      search:'',
      appointmentHistory:[{
        appointDoc:'张伟',
        appointDate:'2022-7-9',
        appointTime:'9:00-10:00',
        appointRemark:'',
        appointState:true,
        appointCancel:false,
      },{
        appointDoc:'李强',
        appointDate:'2022-8-1',
        appointTime:'14:00-15:00',
        appointRemark:'肚子疼',
        appointState:false,
        appointCancel:true,
      },],
      medicalHistory:[{
        medicalDoc:'李强',
        medicalTime:'2022-06-23-9:00',
        medicalRemark:'123',
        medicalResult:'321',
        medicalAdvice:'122333',
      },{
        medicalDoc:'张伟',
        medicalTime:'2022-07-03-10:00',
        medicalRemark:'qwer',
        medicalResult:'asdf',
        medicalAdvice:'zxcvbnm',
      },],

      appointHisString: [],
      treatHisString: [],
      roomType: false,
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    open1(arr){
      Cookies.set('RType',this.roomType,{path:"/"})
      window.open(arr.inviteLink,'_blank')
    },

    open2() {
      this.$notify({
        title: '请稍候',
        message: '医生正在创建远程诊室',
        type: 'warning'
      });
    },

    appointHistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/enterprise/info/listRecordByUid'),
        method: 'get',
        params: this.$http.adornParams({ userId: this.userId , userType: this.userType })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看预约数据")
          console.log(data)
          this.appointHisString = data.recordOrderList
          Vue.set(this.appointHisString,'fixTime','')
          for (var t of this.appointHisString) {
            let xTime= t.dateTime.replace(/-/g, '/')
            let yTime= new Date(xTime)
            yTime.setDate(yTime.getDate()-1)
            let zTime= yTime
            t.fixTime= zTime
          }
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    treatHistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/enterprise/info/listDiagnosisByUid'),
        method: 'get',
        params: this.$http.adornParams({ userId: this.userId , userType: this.userType })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看历史数据")
          console.log(data)
          this.treatHisString = data.recordDiagnosisEntity
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    appointCancel(arr) {
      this.$http({
        url: this.$http.adornUrl('/enterprise/info/cancelOrder'),
        method: 'get',
        params: this.$http.adornParams({orderId: arr.orderId})
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看反馈的数据")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '取消成功',
            type: 'success'
          })
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.appointHistoryGet();
    this.treatHistoryGet();
  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
